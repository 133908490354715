import Vue from 'vue'
import { required, email, max, min, min_value, max_value, confirmed, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'

extend('required', {
  ...required,
  message: '{_field_} tidak boleh kosong'
})

extend('max', {
  ...max,
  message: '{_field_} maksimal {length} karakter'
})

extend('min', {
  ...min,
  message: '{_field_} minimal {length} karakter'
})

extend('min_0', {
  ...min_value,
  message: '{_field_} minimal 0'
})

extend('max_100', {
  ...max_value,
  message: '{_field_} maksimal 100'
})

extend('min_price', {
  ...min_value,
  message: '{_field_} minimal Rp 10.000'
})

extend('max_price', {
  ...max_value,
  message: '{_field_} maksimal Rp 1.000.000'
})

extend('email', {
  ...email,
  message: '{_field_} email belum benar'
})

extend('confirmed', {
  ...confirmed,
  message: '{_field_} konfirmasi password belum benar'
})

extend('regex', {
  ...regex,
  message: '{_field_} harus mengandung 1 huruf kecil, 1 huruf besar & 1 karakter'
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  }
}