import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3dfe7b66 = () => interopDefault(import('../pages/announcement/index.vue' /* webpackChunkName: "pages/announcement/index" */))
const _de82ee04 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _b441665e = () => interopDefault(import('../pages/indexOld.vue' /* webpackChunkName: "pages/indexOld" */))
const _0775841c = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _6bcdf340 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _60d95d68 = () => interopDefault(import('../pages/service/index.vue' /* webpackChunkName: "pages/service/index" */))
const _6e74882b = () => interopDefault(import('../pages/submission/index.vue' /* webpackChunkName: "pages/submission/index" */))
const _372d7bbd = () => interopDefault(import('../pages/auth/check.vue' /* webpackChunkName: "pages/auth/check" */))
const _661ce5a0 = () => interopDefault(import('../pages/auth/checkforgot.vue' /* webpackChunkName: "pages/auth/checkforgot" */))
const _845ab084 = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _8e1d24c4 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _74d74304 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _316d6410 = () => interopDefault(import('../pages/auth/success.vue' /* webpackChunkName: "pages/auth/success" */))
const _63a2df9b = () => interopDefault(import('../pages/auth/successforgot.vue' /* webpackChunkName: "pages/auth/successforgot" */))
const _14ceaabf = () => interopDefault(import('../pages/auth/reset/_slug.vue' /* webpackChunkName: "pages/auth/reset/_slug" */))
const _5ad7a531 = () => interopDefault(import('../pages/auth/verification/_slug.vue' /* webpackChunkName: "pages/auth/verification/_slug" */))
const _7c30e3a2 = () => interopDefault(import('../pages/auth/verification/_slugInject.vue' /* webpackChunkName: "pages/auth/verification/_slugInject" */))
const _2c76e7e6 = () => interopDefault(import('../pages/auth/verification/_slugOld.vue' /* webpackChunkName: "pages/auth/verification/_slugOld" */))
const _abebb8b2 = () => interopDefault(import('../pages/progress/akl/_id.vue' /* webpackChunkName: "pages/progress/akl/_id" */))
const _3e4e92a8 = () => interopDefault(import('../pages/progress/akm/_id.vue' /* webpackChunkName: "pages/progress/akm/_id" */))
const _337577e8 = () => interopDefault(import('../pages/progress/kia/_id.vue' /* webpackChunkName: "pages/progress/kia/_id" */))
const _6877c581 = () => interopDefault(import('../pages/progress/kk/_id.vue' /* webpackChunkName: "pages/progress/kk/_id" */))
const _629fd54c = () => interopDefault(import('../pages/progress/ktp/_id.vue' /* webpackChunkName: "pages/progress/ktp/_id" */))
const _217e7179 = () => interopDefault(import('../pages/progress/rkm/_id.vue' /* webpackChunkName: "pages/progress/rkm/_id" */))
const _7ffb3d31 = () => interopDefault(import('../pages/progress/skd/_id.vue' /* webpackChunkName: "pages/progress/skd/_id" */))
const _7330713d = () => interopDefault(import('../pages/progress/skp/_id.vue' /* webpackChunkName: "pages/progress/skp/_id" */))
const _6f33d84e = () => interopDefault(import('../pages/progress/upd/_id.vue' /* webpackChunkName: "pages/progress/upd/_id" */))
const _778e14da = () => interopDefault(import('../pages/news/_id.vue' /* webpackChunkName: "pages/news/_id" */))
const _718262ee = () => interopDefault(import('../pages/product/_id.vue' /* webpackChunkName: "pages/product/_id" */))
const _6dec6026 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/announcement",
    component: _3dfe7b66,
    name: "announcement"
  }, {
    path: "/home",
    component: _de82ee04,
    name: "home"
  }, {
    path: "/indexOld",
    component: _b441665e,
    name: "indexOld"
  }, {
    path: "/news",
    component: _0775841c,
    name: "news"
  }, {
    path: "/profile",
    component: _6bcdf340,
    name: "profile"
  }, {
    path: "/service",
    component: _60d95d68,
    name: "service"
  }, {
    path: "/submission",
    component: _6e74882b,
    name: "submission"
  }, {
    path: "/auth/check",
    component: _372d7bbd,
    name: "auth-check"
  }, {
    path: "/auth/checkforgot",
    component: _661ce5a0,
    name: "auth-checkforgot"
  }, {
    path: "/auth/forgot",
    component: _845ab084,
    name: "auth-forgot"
  }, {
    path: "/auth/login",
    component: _8e1d24c4,
    name: "auth-login"
  }, {
    path: "/auth/register",
    component: _74d74304,
    name: "auth-register"
  }, {
    path: "/auth/success",
    component: _316d6410,
    name: "auth-success"
  }, {
    path: "/auth/successforgot",
    component: _63a2df9b,
    name: "auth-successforgot"
  }, {
    path: "/auth/reset/:slug?",
    component: _14ceaabf,
    name: "auth-reset-slug"
  }, {
    path: "/auth/verification/:slug?",
    component: _5ad7a531,
    name: "auth-verification-slug"
  }, {
    path: "/auth/verification/:slugInject?",
    component: _7c30e3a2,
    name: "auth-verification-slugInject"
  }, {
    path: "/auth/verification/:slugOld?",
    component: _2c76e7e6,
    name: "auth-verification-slugOld"
  }, {
    path: "/progress/akl/:id?",
    component: _abebb8b2,
    name: "progress-akl-id"
  }, {
    path: "/progress/akm/:id?",
    component: _3e4e92a8,
    name: "progress-akm-id"
  }, {
    path: "/progress/kia/:id?",
    component: _337577e8,
    name: "progress-kia-id"
  }, {
    path: "/progress/kk/:id?",
    component: _6877c581,
    name: "progress-kk-id"
  }, {
    path: "/progress/ktp/:id?",
    component: _629fd54c,
    name: "progress-ktp-id"
  }, {
    path: "/progress/rkm/:id?",
    component: _217e7179,
    name: "progress-rkm-id"
  }, {
    path: "/progress/skd/:id?",
    component: _7ffb3d31,
    name: "progress-skd-id"
  }, {
    path: "/progress/skp/:id?",
    component: _7330713d,
    name: "progress-skp-id"
  }, {
    path: "/progress/upd/:id?",
    component: _6f33d84e,
    name: "progress-upd-id"
  }, {
    path: "/news/:id",
    component: _778e14da,
    name: "news-id"
  }, {
    path: "/product/:id?",
    component: _718262ee,
    name: "product-id"
  }, {
    path: "/",
    component: _6dec6026,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
