const state = () => ({
  intro: false
})

const mutations = {
  setIntro (state, intro) {
    state.intro = intro
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}